export const FirebaseConfig = {
	"projectId": "tharide-e1e3e",
	"appId": "1:486310321482:web:843fe51ef30f4c2d6535b3",
	"databaseURL": "https://tharide-e1e3e-default-rtdb.firebaseio.com",
	"storageBucket": "tharide-e1e3e.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBxVHdPsmSjn-GecEKZg7zlWCq7OXLkwd0",
	"authDomain": "tharide-e1e3e.firebaseapp.com",
	"messagingSenderId": "486310321482",
	"measurementId": "G-2SQ5MK63LD"
};